import { ReactComponent as VolumeHigh } from '@mdi/svg/svg/volume-high.svg';
import { ReactComponent as VolumeLow } from '@mdi/svg/svg/volume-low.svg';
import { ReactComponent as VolumeMedium } from '@mdi/svg/svg/volume-medium.svg';
import { ReactComponent as VolumeMuted } from '@mdi/svg/svg/volume-mute.svg';
import { ReactComponent as VolumeOff } from '@mdi/svg/svg/volume-off.svg';
import * as cx from 'classnames';
import * as React from 'react';

import { VolumeControlButtonProps } from './types';
import * as s from './VolumeControlButton.m.less';

export const VolumeControlButton: React.FC<
  VolumeControlButtonProps
> = function VolumeControlButton({
  className,
  muted,
  onMute,
  onVolume,
  volume,
}) {
  const [visible, setVisible] = React.useState(false);

  const rootRef = React.useRef<HTMLDivElement>();
  const containerRef = React.useRef<HTMLDivElement>();
  const inputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    const ref = rootRef.current;

    const enterListener = (e: MouseEvent): void => {
      setVisible(e.type === 'mouseenter');
    };

    ref.addEventListener('mouseenter', enterListener);
    ref.addEventListener('mouseleave', enterListener);

    return (): void => {
      ref.removeEventListener('mouseenter', enterListener);
      ref.removeEventListener('mouseleave', enterListener);
    };
  }, []);

  React.useEffect(() => {
    if (visible) {
      const ref = containerRef.current;
      const listener = (e: WheelEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        onVolume(Math.max(0, Math.min(1, parseFloat(inputRef.current.value) - e.deltaY / 1000)));
      };

      // no passive!
      ref.addEventListener('wheel', listener);
      return (): void => ref.removeEventListener('wheel', listener);
    }
  }, [onVolume, visible]);

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onVolume(parseFloat(e.currentTarget.value));
  }, [onVolume]);

  const handleMute = React.useCallback(() => {
    onMute();
  }, [onMute]);

  const volumeIcon = (volume === 0 && (<VolumeOff />))
    || (volume < 0.33 && (<VolumeLow />))
    || (volume < 0.66 && (<VolumeMedium />))
    || (<VolumeHigh />);

  return (
    <div className={s.root} ref={rootRef}>
      <button
        aria-label="Volume control"
        className={cx(s.button, className)}
        type="button"
        onClick={handleMute}
      >
        {visible
          ? (muted && volumeIcon) || <VolumeMuted />
          : (muted && <VolumeMuted />) || volumeIcon}
      </button>
      {visible && (
        <div>
          <div ref={containerRef}>
            <input
              max={1}
              min={0}
              onChange={handleChange}
              ref={inputRef}
              step="0.01"
              type="range"
              value={volume}
            />
          </div>
        </div>
      )}
    </div>
  );
};
