/* eslint-disable import/no-unused-modules */
import * as cx from 'classnames';
import { groupBy } from 'lodash/fp';
import {
  Collection, Moment, Player, What, WhatType,
} from 'weplayed-typescript-api';

import { AdminItemTypes } from '../types';
import * as s from './SelectionPanelButtons.m.less';
import {
  SelectionPanelButtonsActions, SelectionPanelButtonsProps,
} from './types';

export const SelectionPanelButtons = function SelectionPanelButtons({
  classNames,
  deleting,
  handler,
  selection,
}: SelectionPanelButtonsProps<AdminItemTypes, WhatType<AdminItemTypes>>): JSX.Element {
  const disabled = selection.length === 0 || deleting;

  const isMoment = selection.is<Moment>(What.MOMENTS);
  const isCollection = selection.is<Collection>(What.COLLECTIONS);
  const isPlayer = selection.is<Player>(What.PLAYERS);
  const isDeleteAllowed = (isMoment || isCollection) && !selection.some((m) => !m.can_manage);
  const isDownloadAllowed = (isMoment || isCollection) && !selection.some((m) => !m.can_download);

  const liked = groupBy(
    ({ followed_by_user, liked_by_user }) => (isPlayer ? followed_by_user : liked_by_user),
    selection,
  );

  return (
    <>
      <button
        className={cx(
          classNames.button,
          s.like,
          liked.true && !liked.false && s.all,
          liked.false && !liked.true && s.none,
        )}
        data-action={SelectionPanelButtonsActions.ADD_TO_FAVORITES}
        disabled={disabled}
        onClick={handler}
        title="Toggle Favorites"
        type="button"
      >
        Toggle Favorites
      </button>
      {isMoment && (
        <button
          className={cx(classNames.button, s.collection)}
          data-action={SelectionPanelButtonsActions.ADD_TO_COLLECTION}
          disabled={disabled}
          onClick={handler}
          title="Add to Playlist"
          type="button"
        >
          Add to Playlist
        </button>
      )}
      {(isMoment || isCollection) && (
        <button
          className={cx(classNames.button, s.download)}
          data-action={SelectionPanelButtonsActions.DOWNLOAD}
          disabled={disabled || !isDownloadAllowed}
          onClick={handler}
          title="Download"
          type="button"
        >
          Download
        </button>
      )}
      {(isMoment || isCollection) && (
        <button
          className={cx(classNames.button, s.remove, deleting && s.loading)}
          data-action={SelectionPanelButtonsActions.DELETE}
          disabled={disabled || !isDeleteAllowed}
          onClick={handler}
          title="Delete"
          type="button"
        >
          Delete
        </button>
      )}
    </>
  );
};
