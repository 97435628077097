import cx from 'classnames';
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';

import { SelectionContext } from './SelectionContext';
import * as s from './SelectionPanel.m.less';
import {
  ActionTrigger, SelectionContextType, SelectionPanelProps,
} from './types';

// eslint-disable-next-line import/no-unused-modules
export const SelectionPanel = function SelectionPanel<
  T extends string = string,
  I extends BaseEntityWithPk = BaseEntityWithPk,
  A extends string = string,
>({
  children,
  context: $context,
  items: $items,
  onAction,
  onSelect,
  type: $type,
}: SelectionPanelProps<T, I, A>): JSX.Element {
  const $$context = React.useContext(
    SelectionContext as React.Context<SelectionContextType<T, I, A>>,
  );

  const { load, selection, register, trigger, set, items } = ($context ?? $$context);

  const type = $type ?? selection.type;

  React.useEffect(() => {
    if (onAction) {
      return register(onAction);
    }
  }, [onAction, register]);

  React.useEffect(() => {
    if ($items) {
      load(type, $items);
    }
  }, [load, $items, type]);

  React.useEffect(() => {
    if (onSelect) {
      onSelect(type, selection);
    }
  }, [type, selection, onSelect]);

  const handler: ActionTrigger<A> = React.useCallback((actionOrEvent) => {
    if (typeof actionOrEvent === 'string') {
      trigger(actionOrEvent);
    } else {
      const action = actionOrEvent.currentTarget.getAttribute('data-action') as A;
      if (action) {
        trigger(action);
      }
    }
  }, [trigger]);

  const handleSelectAll = React.useCallback(() => set(items), [set, items]);
  const handleSelectNone = React.useCallback(() => set([]), [set]);

  // do not show panel if external type is different to what we get from context
  if ($type && $type !== type) {
    return null;
  }

  const extra = typeof children === 'function'
    ? children({
      classNames: { button: s.button },
      handler,
      items,
      selection,
    })
    : children;

  return (
    <div className={cx(s.root, !selection.length && s.hidden)}>
      <div className={s.content}>
        <span>
          {`${selection?.length || 0} selected`}
        </span>
        <Button
          disabled={selection.length === items.length}
          onClick={handleSelectAll}
        >
          Select All
        </Button>
        <Button
          disabled={selection?.length === 0}
          onClick={handleSelectNone}
        >
          Deselect All
        </Button>
        {extra}
      </div>
    </div>
  );
};
