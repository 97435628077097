import cx from 'classnames';
import { Location } from 'history';
/* eslint-disable prefer-arrow-callback */
import * as React from 'react';
import {
  Link, Route, Switch, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import {
  Collection, Game, ID, Moment, PinEntity, Player, What, WhatType,
} from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { usePreviousValue } from 'common/hooks/usePreviousValue';
import { useProfile } from 'common/hooks/useProfile';
import { TypedArray } from 'common/types';
import { EventContext } from 'common/utils/events';
import { pkify } from 'common/utils/helpers';

import {
  CollectionActionDropdown, useCollectionActions,
} from 'cms/components/CollectionActions';
import {
  Action as CollectionAction, ActionType as CollectionActionType,
  BlockCollection,
} from 'cms/components/CollectionActions/types';
import {
  isAction as isCollectionAction,
} from 'cms/components/CollectionActions/utils';
import { CollectionModal } from 'cms/components/CollectionModal';
import { CopyLinkDropdown } from 'cms/components/CopyLinkDropdown';
import { useGameActions } from 'cms/components/GameActions';
import { ActionName } from 'cms/components/Menus/types';
import {
  MomentActionsDropdown, useMomentActions,
} from 'cms/components/MomentActions';
import {
  Action as MomentAction, ActionStatus as MomentActionStatus,
  ActionType as MomentActionType,
} from 'cms/components/MomentActions/types';
import { isAction as isMomentAction } from 'cms/components/MomentActions/utils';
import { usePlayerActions } from 'cms/components/PlayerActions';
import { PopupPlayer } from 'cms/components/PopupPlayer';
import { PlayerMode } from 'cms/components/PopupPlayer/types';
import {
  SelectionContext, SelectionPanel, useCreateSelectionContext,
} from 'cms/components/Selection';
import { ShareSidebar } from 'cms/components/ShareSidebar';
import { StaffEditWarningModal } from 'cms/components/StaffEditWarningModal';
import { Page404 } from 'cms/containers/Errors';
import { useCollection } from 'cms/hooks/useCollection';
import { CreateParams, UpdateParams } from 'cms/hooks/useCollections/types';
import { useMoment } from 'cms/hooks/useMoment';
import { BlockMoment } from 'cms/hooks/useMoments/types';
import { useUploads } from 'cms/hooks/useUploads';
import { GAME$, LIBRARY$, SETTINGS$ } from 'cms/routes';

import * as s from './Admin.m.less';
import { AdminContext, UploadsContext } from './constants';
import { Menu } from './Menu';
import {
  SelectionPanelButtons, SelectionPanelButtonsActions,
} from './SelectionPanelButtons';
import {
  ActionCallbacks, AdminContextType, AdminItemTypes, EntriesShare, EntryLink,
  EntryMenu, EntryShare, PinProps, RegisterActionCallback,
} from './types';

const LibraryPage = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-library" */ './Library')).Library,
}));
const SettingsPage = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-settings" */ './Settings')).Settings,
}));
const GamePage = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-game" */ '../Game')).Game,
}));

const ClientsPage = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-clients" */ './Clients')).Clients,
}));
const StaffPage = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-staff" */ './Staff')).Staff,
}));

const Loader: React.FC = function Loader() {
  useHandleQuery({ isLoading: true });
  return <div />;
};

export const Admin: React.FC = function Admin() {
  const { navType } = useApplication();
  const history = useHistory();
  const location = useLocation();

  const momentActionCallbacks = React.useRef<ActionCallbacks<What.MOMENTS>>({
    onAccept: [],
    onDecline: [],
    onDone: [],
    onStart: [],
  });

  const registerMomentActionCallbacks = React.useCallback(
    (callbacks: RegisterActionCallback<What.MOMENTS>): void => {
      Object.keys(callbacks).forEach((k) => {
        if (!momentActionCallbacks.current[k].includes(callbacks[k])) {
          momentActionCallbacks.current[k].push(callbacks[k]);
        }
      });
    },
    [],
  );

  const unregisterMomentActionCallbacks = React.useCallback(
    (callbacks: RegisterActionCallback<What.MOMENTS>): void => {
      Object.keys(callbacks).forEach((k) => {
        const idx = momentActionCallbacks.current[k].indexOf(callbacks[k]);
        if (idx !== -1) {
          momentActionCallbacks.current[k] = [
            ...momentActionCallbacks.current[k].slice(0, idx),
            ...momentActionCallbacks.current[k].slice(idx + 1),
          ];
        }
      });
    },
    [],
  );

  const selectionContext = useCreateSelectionContext<
    AdminItemTypes,
    WhatType<AdminItemTypes>
  >();

  const {
    selection,
    set: setSelection,
    clear: clearSelection,
    register: registerSelectionAction,
  } = selectionContext;

  React.useEffect(() => {
    navType('sidebar', true);

    return (): void => {
      navType('sidebar', false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { profile: user, saveSettings, settings } = useProfile();

  const prev = usePreviousValue(location);

  const [back, dispatchBack] = React.useReducer((p: Location[], action: 'push' | 'pop' | 'clear') => {
    switch (action) {
      case 'push': return [...p, prev];
      case 'pop': return p.slice(0, -1);
      case 'clear': return [];
      default: return p;
    }
  }, [] as Location[]);

  const pushBack = React.useCallback(() => dispatchBack('push'), []);
  const popBack = React.useCallback(() => dispatchBack('pop'), []);

  const [momentUid, setMomentUid] = React.useState<ID>();
  const [collectionUid, setCollectionUid] = React.useState<ID>();

  const [mode, setMode] = React.useState<PlayerMode>();

  const [
    delayedMomentAction,
    setDelayedMomentAction,
  ] = React.useState<MomentAction<'edit' | 'hide' | 'delete'>>();

  const hasLocationEntity = React.useMemo(
    () => location.hash.includes('playlist=') || location.hash.includes('moment='),
    [location.hash],
  );

  const handleResetEdit = React.useCallback(() => {
    setMode(null);
    if (!hasLocationEntity) {
      setMomentUid(null);
      setCollectionUid(null);
    }
  }, [hasLocationEntity]);

  const showMoment = React.useCallback((uid: ID) => {
    history.replace(`${location.pathname}${location.search}#moment=${uid}`);
  }, [history, location.pathname, location.search]);

  const handleMomentClick = React.useCallback((m: Moment) => {
    showMoment(m.pk);
  }, [showMoment]);

  const handleCollectionClick = React.useCallback((c: Collection) => {
    history.replace(`${location.pathname}${location.search}#playlist=${c.pk}`);
  }, [history, location.pathname, location.search]);

  React.useEffect(() => {
    const [where, uid] = location.hash.replace(/^#/, '').split('=');

    if (where === 'moment') {
      setMomentUid(uid);
      setCollectionUid(null);
    } else if (where === 'playlist') {
      setCollectionUid(uid);
      setMomentUid(null);
    } else if (!where && (momentUid || collectionUid)) {
      setCollectionUid(null);
      setMomentUid(null);
      handleResetEdit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  const handleClosePlayer = React.useCallback(() => {
    if (hasLocationEntity) {
      history.replace(`${location.pathname}${location.search}`);
    } else {
      setMomentUid(null);
      setCollectionUid(null);
    }
  }, [hasLocationEntity, history, location.pathname, location.search]);

  const [menu, setMenu] = React.useState<EntryMenu>();
  const [link, setLink] = React.useState<EntryLink>();
  const [share, setShare] = React.useState<EntryShare>();
  const [shares, setShares] = React.useState<EntriesShare>();

  const { moment: {
    data: momentShared,
    isLoading: isMomentShareLoading,
  } } = useMoment({
    uid: share?.type === What.MOMENTS && share?.uid,
  });

  const { collection: {
    data: collectionShared,
    isLoading: isCollectionShareLoading,
  } } = useCollection({
    summary: true,
    uid: share?.type === What.COLLECTIONS && share?.uid,
  });

  useHandleQuery(
    { isLoading: isMomentShareLoading },
    { isLoading: isCollectionShareLoading },
  );

  React.useEffect(() => {
    if (momentShared) {
      setShares({ type: What.MOMENTS, items: [momentShared] });
    } else if (collectionShared) {
      setShares({ type: What.COLLECTIONS, items: [collectionShared] });
    }
  }, [momentShared, collectionShared]);

  const handleMomentAccept = React.useCallback(
    async <MAT extends MomentActionType>(
      action: MomentAction<MAT>,
      status: (a: keyof MomentActionStatus) => () => void,
    ): Promise<void | false> => {
      // eslint-disable-next-line no-restricted-syntax
      for (const cb of momentActionCallbacks.current.onAccept) {
        // eslint-disable-next-line no-await-in-loop
        if (await cb(action, status) === false) {
          return false;
        }
      }
    },
    [],
  );

  const handleMomentDecline = React.useCallback(
    <MAT extends MomentActionType>(action: MomentAction<MAT>) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const cb of momentActionCallbacks.current.onDecline) {
        // eslint-disable-next-line no-await-in-loop
        if (cb(action) === false) {
          return;
        }
      }
    },
    [],
  );

  const handleMomentStart = React.useCallback(
    (a: MomentAction<MomentActionType>): false | void => {
      // eslint-disable-next-line no-restricted-syntax
      for (const cb of momentActionCallbacks.current.onStart) {
        if (cb(a) === false) {
          return false;
        }
      }

      if (isMomentAction(a, 'select')) {
        if (selection.is<Moment>(What.MOMENTS)) {
          setSelection(a.moment, a.selected);
        }
        return false;
      }

      if (isMomentAction(a, 'share')) {
        handleClosePlayer();
        setShare({ type: What.MOMENTS, uid: a.moment.pk });
        return false;
      }

      if (isMomentAction(a, 'publish') && !a.publish) {
        handleClosePlayer();
      }

      if (!delayedMomentAction
          && user.is_staff
          && settings.staff_edit_warning
          && (
            isMomentAction(a, 'edit') || isMomentAction(a, 'hide') || isMomentAction(a, 'delete')
          )
          && a.moment.is_base
      ) {
        setDelayedMomentAction(a);
        return false;
      }

      if (isMomentAction(a, 'edit') || isMomentAction(a, 'clone')) {
        setMomentUid(a.moment.pk);
        setMode(isMomentAction(a, 'edit') ? PlayerMode.MOMENT_EDIT : PlayerMode.MOMENT_CLONE);
        return false;
      }
    },
    [
      delayedMomentAction,
      handleClosePlayer,
      selection,
      setSelection,
      settings.staff_edit_warning,
      user.is_staff,
    ],
  );

  const handleCollectionStart = React.useCallback(
    (action: CollectionAction<CollectionActionType>): false | void => {
      if (isCollectionAction(action, 'share')) {
        handleClosePlayer();
        setShare({ type: What.COLLECTIONS, uid: action.uid });
        return false;
      }

      if (isCollectionAction(action, 'publish') && !action.publish) {
        handleClosePlayer();
      }

      if (
          (isCollectionAction(action, 'update') || isCollectionAction(action, 'clone'))
          && !action.collection
      ) {
        setCollectionUid(action.uid);
        setMode(isCollectionAction(action, 'clone')
          ? PlayerMode.COLLECTION_CLONE
          : PlayerMode.COLLECTION_EDIT);
      }
    },
    [handleClosePlayer],
  );

  const handleMomentDone = React.useCallback(
    (a: MomentAction<MomentActionType>, result) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const cb of momentActionCallbacks.current.onDone) {
        if (cb(a, result) === false) {
          return;
        }
      }

      if (isMomentAction(a, 'create') || isMomentAction(a, 'update')) {
        if (isMomentAction(a, 'create')) {
          showMoment(result);
        }
      } else if (isMomentAction(a, 'delete') && momentUid) {
        handleClosePlayer();
      }
    },
    [momentUid, showMoment, handleClosePlayer],
  );

  const handleCollectionDone = React.useCallback(
    function handleCollectionDone<TT extends CollectionActionType>(
      action: CollectionAction<TT>,
      result,
    ) {
      const isClone = isCollectionAction(action, 'clone');
      const isUpdate = isCollectionAction(action, 'update');
      if (isClone || isUpdate) {
        if (isClone) {
          setCollectionUid(result);
        }
        setMomentUid(null);
        setMode(null);
      } else if (isCollectionAction(action, 'remove')) {
        handleClosePlayer();
      }
    },
    [handleClosePlayer],
  );

  const collectionActions = useCollectionActions({
    onDone: handleCollectionDone,
    onStart: handleCollectionStart,
    sendDownloadEmail: settings.send_download_email,
    user,
  });
  const gameActions = useGameActions({
    user,
  });
  const momentActions = useMomentActions({
    onAccept: handleMomentAccept,
    onDecline: handleMomentDecline,
    onDone: handleMomentDone,
    onStart: handleMomentStart,
    sendDownloadEmail: settings.send_download_email,
    user,
  });
  const playerActions = usePlayerActions({
    user,
  });

  // make invoke functions for easier dependency management
  const { invoke: momentInvoke } = momentActions;
  const { invoke: collectionInvoke } = collectionActions;

  const handleOpenCollectionMenu = React.useCallback(
    (c: Collection, target: HTMLElement, override?: Record<ActionName, boolean>) => {
      setMenu({ type: What.COLLECTIONS, item: c, target, override });
    },
    [],
  );

  const handleCollectionLike = React.useCallback(({ pk }: Collection, like: boolean) => {
    collectionInvoke({ action: 'like', like, uid: pk });
  }, [collectionInvoke]);

  const handleGameLike = React.useCallback(({ pk }: Game, like: boolean) => {
    gameActions.invoke({ action: 'like', like, uid: pk });
  }, [gameActions]);

  const handleOpenMomentMenu = React.useCallback(
    (c: Moment, target: HTMLElement, override?: Record<ActionName, boolean>) => {
      setMenu({ type: What.MOMENTS, item: c, target, override });
    },
    [],
  );

  const handleMomentEdit = React.useCallback((mm: Moment) => {
    momentInvoke({ action: 'edit', moment: mm });
  }, [momentInvoke]);

  const handleMomentLike = React.useCallback((mm: Moment, like: boolean) => {
    momentInvoke({ action: 'like', like, moment: mm });
  }, [momentInvoke]);

  const handleCloseMenu = React.useCallback(() => {
    setMenu(null);
  }, []);

  const handlePlayerLike = React.useCallback(({ pk }: Player, like: boolean) => {
    playerActions.invoke({ action: 'like', like, uid: pk });
  }, [playerActions]);

  const handleCollectionSave = React.useCallback((uid: ID, c: CreateParams | UpdateParams) => {
    if (uid) {
      // update
      collectionInvoke({
        action: 'update',
        collection: c as UpdateParams,
        uid,
      });
    } else if ('uid' in c) {
      // clone
      collectionInvoke({
        action: 'clone',
        collection: c,
        success: 'Playlist cloned successfully and saved to your library',
        uid,
      });
    } else {
      // create
      collectionInvoke({
        action: 'create',
        collection: c,
        uid: null,
      });
    }
  }, [collectionInvoke]);

  const handleMomentSave = React.useCallback((m: Moment) => {
    momentInvoke({
      action: m.pk ? 'update' : 'create',
      success: !m.pk ? 'Moment cloned successfully and saved to your library' : null,
      fail: !m.pk ? 'Moment clone failed' : null,
      moment: m,
    });
  }, [momentInvoke]);

  React.useEffect(() => registerSelectionAction((
    $action: SelectionPanelButtonsActions,
    $selection: TypedArray<WhatType<AdminItemTypes>, AdminItemTypes>,
  ) => {
    switch ($action) {
      case SelectionPanelButtonsActions.ADD_TO_FAVORITES: {
        if ($selection.is<Collection>(What.COLLECTIONS)) {
          const like = !$selection.every(({ liked_by_user }) => liked_by_user);
          collectionInvoke({
            action: 'bulk_like',
            like,
            uids: $selection
              .filter(({ liked_by_user }) => liked_by_user !== like)
              .map(pkify),
          });
        } else if ($selection.is<Moment>(What.MOMENTS)) {
          const like = !$selection.every(({ liked_by_user }) => liked_by_user);
          momentInvoke({
            action: 'bulk_like',
            like,
            moments: $selection.filter(({ liked_by_user }) => liked_by_user !== like),
          });
        } else if ($selection.is<Game>(What.GAMES)) {
          const like = !$selection.every(({ liked_by_user }) => liked_by_user);
          gameActions.invoke({
            action: 'bulk_like',
            like,
            uids: $selection
              .filter(({ liked_by_user }) => liked_by_user !== like)
              .map(pkify),
            uid: null,
          });
        } else if ($selection.is<Player>(What.PLAYERS)) {
          const like = !$selection.every(({ followed_by_user }) => followed_by_user);
          playerActions.invoke({
            action: 'bulk_like',
            like,
            uids: $selection
              .filter(({ followed_by_user }) => followed_by_user !== like)
              .map(pkify),
            uid: null,
          });
        }

        break;
      }

      case SelectionPanelButtonsActions.ADD_TO_COLLECTION: {
        if ($selection.is<Moment>(What.MOMENTS)) {
          momentInvoke({
            action: 'collections',
            moment: null,
            moments: $selection,
          });
        }

        break;
      }

      case SelectionPanelButtonsActions.DELETE: {
        if ($selection.is<Moment>(What.MOMENTS)) {
          momentInvoke({
            action: 'bulk_delete',
            moments: $selection,
          });
        } else if ($selection.is(What.COLLECTIONS)) {
          collectionInvoke({
            action: 'bulk_delete',
            uids: $selection.map(pkify),
          });
        }

        break;
      }

      case SelectionPanelButtonsActions.DOWNLOAD: {
        if ($selection.is<Moment>(What.MOMENTS)) {
          momentInvoke({
            action: 'bulk_download',
            moments: $selection,
          });
        } else if ($selection.is<Collection>(What.COLLECTIONS)) {
          collectionInvoke({
            action: 'bulk_download',
            uids: $selection.map(pkify),
          });
        }

        break;
      }

      default:
        // eslint-disable-next-line no-console
        console.warn('Unhandled action', $action);
    }

    clearSelection();

    return false;
  }), [
    collectionInvoke,
    gameActions,
    momentInvoke,
    playerActions,
    registerSelectionAction,
    clearSelection,
  ]);

  const handleMomentBlock = React.useCallback((item: Moment, block: BlockMoment) => {
    momentInvoke({
      action: 'block',
      moment: item,
      block,
    });
  }, [momentInvoke]);

  const handleCollectionBlock = React.useCallback((item: Collection, block: BlockCollection) => {
    collectionInvoke({
      action: 'block',
      uid: item.pk,
      block,
    });
  }, [collectionInvoke]);

  const handleSelect = React.useCallback(
    <T extends AdminItemTypes>(type: T, item?: WhatType<T>): void => {
      if (selection.is<WhatType<AdminItemTypes>>(type) && item) {
        setSelection(item);
      } else if (!item) {
        clearSelection();
      }
    },
    [clearSelection, selection, setSelection],
  );

  const handleMomentPin = React.useCallback((props: PinProps<Moment>) => {
    momentInvoke({
      action: 'pin',
      moment: props.item,
      orgId: null,
      pin: {
        orgId: props.orgId,
        pin: null, // intentionally
        what: props.what,
        where: props.where,
      } as PinEntity,
    });
  }, [momentInvoke]);

  const handleMomentDelete = React.useCallback((moment: Moment): void => {
    momentInvoke({
      action: 'delete',
      moment,
    });
  }, [momentInvoke]);

  const handleCollectionPin = React.useCallback(
    (props: PinProps<Collection>) => {
      collectionInvoke({
        action: 'pin',
        uid: props.item.pk,
        pin: {
          pin: null, // intentionally
          orgId: props.orgId,
          what: props.what,
          where: props.where,
        } as PinEntity,
      });
    },
    [collectionInvoke],
  );

  const handleMomentLink = React.useCallback(
    (m: Moment, target: HTMLElement) => setLink({
      type: What.MOMENTS,
      item: m,
      target,
    }),
    [],
  );

  const handleCollectionLink = React.useCallback(
    (m: Collection, target: HTMLElement) => setLink({
      type: What.COLLECTIONS,
      item: m,
      target,
    }),
    [],
  );

  const handleGameLink = React.useCallback(
    (g: Game, target: HTMLElement) => setLink({
      type: What.GAMES,
      item: g,
      target,
    }),
    [],
  );

  const handlePlayerLink = React.useCallback(
    (a: Player, target: HTMLElement) => setLink({
      type: What.PLAYERS,
      item: a,
      target,
    }),
    [],
  );

  const handleLinkClose = React.useCallback(() => setLink(null), []);

  const handleShareClose = React.useCallback(() => {
    setShare(null);
    setShares(null);
  }, []);

  const isPlayerOpen = Boolean(momentUid || collectionUid);

  const handleConfirmStaffBaseAction = React.useCallback((remember: boolean): void => {
    setDelayedMomentAction(null);
    momentInvoke(delayedMomentAction);
    if (remember) {
      saveSettings({ staff_edit_warning: false });
    }
  }, [delayedMomentAction, momentInvoke, saveSettings]);

  const handleCancelStaffBaseAction = React.useCallback((): void => {
    setDelayedMomentAction(null);
  }, []);

  const value = React.useMemo<AdminContextType>(() => ({
    isPlayerOpen,
    mode,
    onCollectionBlock: handleCollectionBlock,
    onCollectionClick: handleCollectionClick,
    onCollectionLike: handleCollectionLike,
    onCollectionLink: handleCollectionLink,
    onCollectionMenu: handleOpenCollectionMenu,
    onCollectionPin: handleCollectionPin,
    onGameLike: handleGameLike,
    onGameLink: handleGameLink,
    onMomentBlock: handleMomentBlock,
    onMomentClick: handleMomentClick,
    onMomentDelete: handleMomentDelete,
    onMomentLike: handleMomentLike,
    onMomentLink: handleMomentLink,
    onMomentMenu: handleOpenMomentMenu,
    onMomentPin: handleMomentPin,
    onPlayerLike: handlePlayerLike,
    onPlayerLink: handlePlayerLink,
    onSelect: handleSelect,
    popBack,
    pushBack,
    registerMomentActionCallbacks,
    status: {
      moments: momentActions.status,
      collections: collectionActions.status,
    },
    unregisterMomentActionCallbacks,
  }), [
    mode, isPlayerOpen,
    handleMomentClick, handleOpenMomentMenu, handleMomentLike, handleMomentBlock,
    handleCollectionClick, handleOpenCollectionMenu, handleCollectionLike, handleCollectionBlock,
    handleSelect, handleGameLike, handlePlayerLike, handleMomentPin,
    momentActions.status, collectionActions.status, pushBack, popBack,
    handleCollectionPin, handleCollectionLink, handleMomentLink, handleGameLink, handlePlayerLink,
    registerMomentActionCallbacks, unregisterMomentActionCallbacks, handleMomentDelete,
  ]);

  const uploads = useUploads();

  const active = Boolean(uploads.active);

  React.useEffect(() => {
    if (active) {
      const text = 'One or more videos are uploading, do you want to leave the page?';
      const listener = (e: BeforeUnloadEvent): string => {
        e.preventDefault();
        e.returnValue = text;
        return text;
      };

      window.addEventListener('beforeunload', listener, false);
      return (): void => window.removeEventListener('beforeunload', listener, false);
    }
  }, [active]);

  // const handleSelectPanelSelect = React.useCallback(
  //   ($items): void => {
  //     if (selection.is<Moment>(What.MOMENTS)
  //       || selection.is<Collection>(What.COLLECTIONS)
  //       || selection.is<Game>(What.GAMES)
  //       || selection.is<Player>(What.PLAYERS)) {
  //       setSelection($items);
  //     }
  //   },
  //   [selection, setSelection],
  // );

  const isGameRoute = Boolean(useRouteMatch(GAME$.route));

  return (
    <AdminContext.Provider value={value}>
      <SelectionContext.Provider value={selectionContext}>
        <div className={s.root}>
          <Menu
            progress={uploads.stats}
            user={user}
          />
          <div className={cx(s.content, isGameRoute && s.full)}>
            {back.length && back[back.length - 1] ? (
              <Link to={back[back.length - 1]} className={s.back}>
                Back
              </Link>
            ) : null}
            <UploadsContext.Provider value={uploads}>
              <React.Suspense fallback={<Loader />}>
                <Switch>
                  <Route component={GamePage} path={GAME$.route} />
                  <Route component={SettingsPage} path={SETTINGS$.route} />
                  <Route component={LibraryPage} path={LIBRARY$.route} />
                  {user.is_staff && !user.is_cms_user && <StaffPage />}
                  {!user.is_staff && user.is_cms_user && <ClientsPage />}
                  <Route path="*" component={Page404} />
                </Switch>
              </React.Suspense>
            </UploadsContext.Provider>
          </div>
          {(selection.is<WhatType<AdminItemTypes>>(
            What.MOMENTS,
            What.COLLECTIONS,
            What.GAMES,
            What.PLAYERS,
          )) && (
            <SelectionPanel<AdminItemTypes, WhatType<AdminItemTypes>, SelectionPanelButtonsActions>>
              {(props): JSX.Element => (
                <SelectionPanelButtons
                  {...props}
                  deleting={Boolean(
                    momentActions.status.delete.length + collectionActions.status.remove.length,
                  )}
                />
              )}
            </SelectionPanel>
          )}
        </div>
        <PopupPlayer
          collectionId={collectionUid}
          disabled={momentActions.status.create.includes(null)
            || (momentActions.status.update.length !== 0)
            || collectionActions.status.clone.includes(null)
            || (collectionUid && collectionActions.status.update.includes(collectionUid))}
          mode={mode}
          momentId={momentUid}
          onCancel={handleResetEdit}
          onClose={handleClosePlayer}
          onCollectionLike={handleCollectionLike}
          onCollectionLink={handleCollectionLink}
          onCollectionMenu={handleOpenCollectionMenu}
          onMomentEdit={handleMomentEdit}
          onMomentLike={handleMomentLike}
          onMomentLink={handleMomentLink}
          onMomentMenu={handleOpenMomentMenu}
          onMomentSave={handleMomentSave}
          user={user}
        />
        {collectionActions.modals}
        {momentActions.modals}
        {menu?.type === What.COLLECTIONS && (
          <CollectionActionDropdown
            className={s.dropdown}
            item={menu.item as Collection}
            context={EventContext.ADMIN}
            invoke={collectionInvoke}
            onClose={handleCloseMenu}
            selected={selection.is(What.COLLECTIONS) && selection.includes(menu.item)}
            showClone={menu.override?.clone !== false && menu.item.can_clone}
            showDownload={menu.item.can_download}
            showEdit={menu.override?.edit !== false && menu.item.can_manage}
            showInfo
            showPublish={menu.override?.publish !== false
                          && user.can_publish_content && !user.is_staff}
            showRemove={menu.override?.remove !== false && menu.item.can_manage}
            showShare={!user.is_staff}
            showVisibility={user.is_staff}
            status={collectionActions.status}
            target={menu.target}
            user={user}
          />
        )}
        {menu?.type === What.MOMENTS && (
          <MomentActionsDropdown
            className={s.dropdown}
            invoke={momentInvoke}
            item={menu.item as Moment}
            onClose={handleCloseMenu}
            selected={selection.is(What.MOMENTS) && selection.includes(menu.item)}
            showClone={!collectionUid && menu.override?.clone !== false}
            showCollection
            showDelete={menu.override?.delete !== false && menu.item.can_manage}
            showDownload={menu.item.can_download}
            showEdit={menu.override?.edit !== false && menu.item.can_manage}
            showFlag={menu.override?.flat !== false && user.is_staff}
            showGame={menu.override?.game !== false}
            showInfo
            showReview={menu.override?.review !== false && user.is_staff}
            showPublish={menu.override?.publish !== false
                          && user.can_publish_content && !user.is_staff}
            showSelect={menu.override?.select}
            showShare={!user.is_staff}
            showVisibility={user.is_staff}
            status={momentActions.status}
            target={menu.target}
            user={user}
          />
        )}
        {link && (
          <CopyLinkDropdown
            context={EventContext.ADMIN}
            item={link.item}
            onClose={handleLinkClose}
            orgId={user.org?.pk || user.conference?.pk}
            target={link.target}
            type={link.type}
            userId={user?.pk}
          />
        )}
        <ShareSidebar
          items={shares?.items}
          onClose={handleShareClose}
          type={shares?.type}
          user={user}
        />
        <CollectionModal
          uid={(mode === PlayerMode.COLLECTION_EDIT || mode === PlayerMode.COLLECTION_CLONE)
            && collectionUid}
          clone={mode === PlayerMode.COLLECTION_CLONE}
          user={user}
          onClose={handleResetEdit}
          onMomentSave={handleMomentSave}
          onSave={handleCollectionSave}
          onSuccess={handleResetEdit}
          saving={momentActions.status.create.includes(null)
            || (momentActions.status.update.length !== 0)
            || collectionActions.status.clone.includes(null)
            || (collectionUid && collectionActions.status.update.includes(collectionUid))}
        />
        {user.is_staff && (
          <StaffEditWarningModal
            onCancel={handleCancelStaffBaseAction}
            onConfirm={handleConfirmStaffBaseAction}
            show={Boolean(delayedMomentAction)}
          />
        )}
      </SelectionContext.Provider>
    </AdminContext.Provider>
  );
};
