import cx from 'classnames';
import { noop } from 'lodash/fp';
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { getDataAttributes } from 'common/utils/drag';

import { EntitiesListContext } from '../../constants';
import { BaseItemProps } from '../../types';
import { More } from '../Controls';
import * as s from './Items.m.less';

const onDragStart = (
  e: React.DragEvent,
): void => (e.target as HTMLDivElement).classList.add(s.noEffect);

const onDragEnd = (
  e: React.DragEvent,
): void => (e.target as HTMLDivElement).classList.remove(s.noEffect);

export const Base = function Base<
  T extends BaseEntityWithPk
>({
  checkbox,
  children,
  className,
  content,
  controls,
  disabled,
  hidden,
  highlighted,
  index,
  item,
  selected,
}: BaseItemProps<T>): JSX.Element {
  const {
    content: contentGenerator,
    handleClick,
    handleContainerDrag,
    handleEnter,
    handleExpand: handleMore,
    handleSelect,
    hasExpand,
    selectable,
  } = React.useContext(EntitiesListContext);

  const expand = !hasExpand || hasExpand(item);

  const selectionAllowed = selectable !== false
    && (selectable === true || selectable.some(($s) => $s === item));

  return (
    <>
      <tr
        className={cx(
          s.item,
          (typeof index === 'number') && (index % 2 === 0) && s.odd,
          (highlighted || selected || content) && s.highlighted,
          disabled && s.disabled,
          !selectionAllowed && s.disallowed,
          hidden && s.hidden,
          className,
        )}
        draggable={Boolean(!content && handleContainerDrag)}
        onClick={handleClick}
        onKeyUp={handleEnter}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        tabIndex={handleClick ? 0 : undefined}
        {...getDataAttributes(item)}
      >
        {handleSelect && (
          <td className={s.checkbox}>
            <button type="button" onClick={handleSelect}>
              {checkbox || <input type="checkbox" checked={selected || false} onChange={noop} />}
            </button>
          </td>
        )}
        {children}
        {controls?.map((control) => (control?.props.onClick ? (
          <td
            className={cx(s.control, control.props.className)}
            key={control.key}
          >
            {control}
          </td>
        ) : null))}
        {contentGenerator && (
          <td className={cx(s.control, s.visible)}>
            {(!hasExpand || hasExpand(item)) && (
              (expand === true ? <More open={Boolean(content)} onClick={handleMore} /> : expand)
            )}
          </td>
        )}
        <td className={s.select}>
          <button onClick={handleSelect} type="button" disabled={!selectionAllowed}>
            Select
          </button>
        </td>
      </tr>
      {content && (
        <tr>
          <td colSpan={100} className={s.extra}>
            {content}
          </td>
        </tr>
      )}
    </>
  );
};
