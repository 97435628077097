import * as React from 'react';

import { SHORTCUT, SHORTCUT_KEYS } from './constants';
import { VideoPlayerHelpProps } from './types';
import * as s from './VideoPlayer.m.less';

const ShortCuts = function ShortCut(...shortcuts: SHORTCUT[]): JSX.Element[] {
  return shortcuts.map((shortcut) => (
    <React.Fragment key={shortcut}>
      <dt title={SHORTCUT_KEYS[shortcut][1]}>{SHORTCUT_KEYS[shortcut][2]}</dt>
      <dd>{SHORTCUT_KEYS[shortcut][3]}</dd>
    </React.Fragment>
  ));
};

export const VideoPlayerHelp: React.FC<VideoPlayerHelpProps> = function VideoPlayerHelp({
  allowFullscreen,
  help,
  onNext,
  onPrevious,
  vtt,
}) {
  return (
    <div className={s.help}>
      Player Controls
      <dl>
        {ShortCuts(
          SHORTCUT.PAUSE_RESUME,
          SHORTCUT.CONTROLS,
          SHORTCUT.SPEED_UP,
          SHORTCUT.SLOW_DOWN,
          SHORTCUT.SKIP_BACK,
          SHORTCUT.SKIP_FWD,
          SHORTCUT.BEGIN,
          SHORTCUT.END,
          SHORTCUT.VOLUME_UP,
          SHORTCUT.VOLUME_DOWN,
          SHORTCUT.VOLUME_MUTE,
        )}
        {onPrevious && ShortCuts(SHORTCUT.PREV)}
        {onNext && ShortCuts(SHORTCUT.NEXT)}
        {allowFullscreen && ShortCuts(SHORTCUT.FULLSCREEN)}
        {vtt && ShortCuts(SHORTCUT.CC)}
        {ShortCuts(SHORTCUT.HELP)}
      </dl>
      {help}
    </div>
  );
};
