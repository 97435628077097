import cx from 'classnames';
import { noop } from 'lodash/fp';
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { getDataAttributes } from 'common/utils/drag';

import { EntitiesListContext } from '../../constants';
import { BaseItemProps } from '../../types';
import * as s from './Items.m.less';

const onDragStart = (
  e: React.DragEvent,
): void => (e.target as HTMLDivElement).classList.add(s.noEffect);

const onDragEnd = (
  e: React.DragEvent,
): void => (e.target as HTMLDivElement).classList.remove(s.noEffect);

export const Base = function Base<
  T extends BaseEntityWithPk
>({
  checkbox,
  children,
  content: _content, // not implemented
  controls,
  className,
  disabled,
  hidden,
  highlighted,
  index: _index, // not used
  item,
  selected,
}: React.PropsWithChildren<BaseItemProps<T>>): JSX.Element {
  const {
    handleClick,
    handleContainerDrag,
    handleEnter,
    handleSelect,
    selectable,
  } = React.useContext(EntitiesListContext);

  const selectionAllowed = selectable !== false
    && (selectable === true || selectable.some(($s) => $s === item));

  return (
    <div
      className={cx(
        s.item,
        (highlighted || selected) && s.highlighted,
        disabled && s.disabled,
        !selectionAllowed && s.disallowed,
        hidden && s.hidden,
        className,
      )}
      draggable={Boolean(handleContainerDrag)}
      onClick={handleClick}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onKeyUp={handleEnter}
      role="button"
      tabIndex={handleClick ? 0 : undefined}
      {...getDataAttributes(item)}
    >
      <div className={s.top}>
        {controls?.map((control) => (control?.props.onClick ? (
          <div
            className={cx(s.control, control.props.className)}
            key={control.key}
          >
            {control}
          </div>
        ) : null))}
        {handleSelect && (
          <button
            className={s.checkbox}
            onClick={handleSelect}
            tabIndex={-1}
            type="button"
          >
            {checkbox || <input type="checkbox" checked={selected || false} onChange={noop} />}
          </button>
        )}
      </div>
      {children}
      <button
        className={s.select}
        disabled={!selectionAllowed}
        onClick={handleSelect}
        type="button"
      >
        Select
      </button>
    </div>
  );
};
