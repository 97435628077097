import { What, WhatType } from 'weplayed-typescript-api';

import { ChildrenRendererProps } from 'cms/components/Selection';

// import { AdminItemTypes } from '../types';/

export enum SelectionPanelButtonsActions {
  ADD_TO_COLLECTION = 'collection',
  ADD_TO_FAVORITES = 'favorites',
  DOWNLOAD = 'download',
  DELETE = 'delete',
}

export interface SelectionPanelButtonsProps<T extends What, I extends WhatType<T>>
    extends ChildrenRendererProps<T, I, SelectionPanelButtonsActions>
{
  deleting?: boolean;
}
