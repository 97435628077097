/* eslint-disable import/no-unused-modules */
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { TypedArray } from 'common/types';
import { pkify } from 'common/utils/helpers';

import {
  Action, SelectionAction, SelectionContextType, State,
  UseCreateSelectionContextArgs,
} from './types';

export const useCreateSelectionContext = function useCreateSelectionContext<
  T extends string = string,
  I extends BaseEntityWithPk = BaseEntityWithPk,
  A extends string = string
>({
  onAction,
}: UseCreateSelectionContextArgs<T, I, A> = {}): SelectionContextType<T, I, A> {
  const [{ items, selection }, dispatch] = React.useReducer(
    (s: State<T, I>, a: Action<T, I>): State<T, I> => {
      switch (a.action) {
        case 'load': return {
          ...s,
          items: a.items,
          selection: a.type !== s.selection.type
            ? new TypedArray<I, T>(a.type)
            : new TypedArray<I, T>(
              a.type,
              ...s.selection.map(({ pk }) => a.items.find((i) => i.pk === pk)).filter(Boolean),
            ),
        };

        case 'clear': return {
          ...s,
          selection: (s.selection.length === 0 ? s.selection : new TypedArray(s.selection.type)),
        };

        case 'set': {
          if (s.selection.type) {
            if ('items' in a) {
              return { ...s, selection: new TypedArray<I, T>(s.selection.type, ...a.items) };
            }

            if ('item' in a) {
              const idx = s.selection.findIndex(({ pk }) => pk === a.item.pk);

              if (idx !== -1 && (a.select === false || typeof a.select !== 'boolean')) {
                return {
                  ...s,
                  selection: new TypedArray<I, T>(
                    s.selection.type,
                    ...s.selection.slice(0, idx),
                    ...s.selection.slice(idx + 1),
                  ),
                };
              }

              if (idx === -1 && (a.select === true || typeof a.select !== 'boolean')) {
                return {
                  ...s,
                  selection: new TypedArray<I, T>(
                    s.selection.type,
                    ...s.selection,
                    a.item,
                  ),
                };
              }
            }
          }

          return s;
        }

        default: return s;
      }
    },
    { items: [], selection: new TypedArray<I, T>(null) },
  );

  const actions = React.useRef<SelectionAction<T, I, A>[]>([]);

  // remove item from selected if it has gone from items
  React.useEffect(() => {
    // find all pk from selection and filter
    // out those are in items already
    const pks = selection
      .map(pkify)
      .filter((pk) => !items.find(({ pk: itemPk }) => itemPk === pk));

    // filter selection
    if (pks.length) {
      dispatch({
        action: 'set',
        items: selection.filter(({ pk }) => !pks.includes(pk)),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const clear: SelectionContextType<T, I, A>['clear'] = React.useCallback(
    () => dispatch({ action: 'clear' }),
    [],
  );

  const load: SelectionContextType<T, I, A>['load'] = React.useCallback(
    ($type: T, $items: I[]): void => (
      dispatch({ action: 'load', type: $type, items: $items })
    ),
    [],
  );

  const set: SelectionContextType<T, I, A>['set'] = React.useCallback((data, select?): void => {
    dispatch({
      action: 'set',
      ...(Array.isArray(data) ? { items: data } : { item: data, select }),
    });
  }, []);

  const register: SelectionContextType<T, I, A>['register'] = React.useCallback((action): () => void => {
    actions.current.push(action);
    return (): void => {
      actions.current = actions.current.filter((a) => a !== action);
    };
  }, []);

  const trigger: SelectionContextType<T, I, A>['trigger'] = React.useCallback((action): void => {
    for (let i = 0; i < actions.current.length; i += 1) {
      if (actions.current[i].call(null, action, selection) === false) {
        break;
      }
    }
  }, [actions, selection]);

  React.useEffect(() => {
    if (onAction) {
      return register(onAction);
    }
  }, [onAction, register]);

  return React.useMemo(() => ({
    clear,
    items,
    load,
    register,
    selection,
    set,
    trigger,
  }), [clear, items, load, register, selection, set, trigger]);
};
