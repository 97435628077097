import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { SelectionContextType } from './types';

export const SelectionContext = React.createContext<
  SelectionContextType<string, BaseEntityWithPk, string>
>({
  clear: null,
  items: null,
  load: null,
  register: null,
  selection: null,
  set: null,
  trigger: null,
});
