import noop from 'lodash/fp/noop';
import * as React from 'react';
import videojs from 'video.js';

import { Debug } from 'common/utils/debug';

import {
  ShortcutKey, VideoPlayerContextType, VideoPlayerPropsOptional,
} from './types';

/**
 * Debug output controller
 */
export const debug = Debug.extend('VideoPlayer');

/**
 * Interval between `onPosition` call.
 * Value in ms
 */
export const POSITION_UPDATE_MAXWAIT = 100;

/**
 * Controls visibility timeout before controls disappear
 * without interaction. Value in ms.
 */
export const CONTROLS_TIMEOUT = 1000;

/**
 * Shift time for forward and rewind actions
 * Value in ms.
 */
export const SHIFT_TIME = 3000;

/**
 * Time span for fade actions, value in ms
 */
export const FADE_TIME = 1000;

/**
 * Minimum fade value
 * Do not set to 0 otherwise there will be problems with autostart
 */
export const MIN_FADE = 0.01;

/**
 * Max allowed playing rate
 */
export const MAX_SPEED = 8;

/**
 * Min allowed playing rate
 */
export const MIN_SPEED = 0.125;

/**
 * Video player context for included controls and children components
 */
export const VideoPlayerContext = React.createContext<VideoPlayerContextType>({
  captions: true,
  controls: true,
  duration: noop,
  edge: false,
  fullscreen: false,
  help: false,
  handleCaptions: null,
  handleFaster: noop,
  handleForward: noop,
  handleFullscreen: noop,
  handleHelp: noop,
  handleMute: noop,
  handlePlayPause: noop,
  handleRewind: noop,
  handleSeek: noop,
  handleSlower: noop,
  handleVolume: noop,
  live: false,
  muted: false,
  playing: false,
  position: noop,
  speed: 1,
  volume: 1,
});

/**
 * Default component props
 */
export const defaultProps: VideoPlayerPropsOptional = {
  adTagUrl: '',
  allowFullscreen: true,
  children: null,
  controls: true,
  end: null,
  fade: false,
  help: null,
  keyboard: true,
  loader: true,
  loop: false,
  onAd: null,
  onEnd: null,
  onFullscreen: null,
  onKeyDown: null,
  onNext: null,
  onPosition: null,
  onPrevious: null,
  onState: null,
  playing: true,
  position: null,
  poster: '',
  start: null,
  title: '',
  volume: null,
  vtt: null,
};

/**
 * Default player init options
 */
export const playerOptions = {
  /**
   * Disable autoplay, it is controlled by code
   * Never set it to true
   */
  autoplay: false,

  /**
   * Player layout
   */
  fluid: true,

  /**
   * Always play inline, it is vital for playback in iOS to leave it enabled
   */
  playsinline: true,

  /**
   * Do not use internal controls
   */
  controls: false,

  /**
   * Do not mute player on creation
   */
  muted: false,

  /**
   * Force video preload automatically
   */
  preload: 'auto',

  /**
   * VideoJS children components configuration
   */
  children: {
    /**
     * Default media loader
     */
    mediaLoader: {},

    /**
     * Do not use embedded spinner
     */
    loadingSpinner: false,

    /**
     * Use live tracker for live games
     */
    liveTracker: true,

    /**
     * Control bar with no components
     */
    controlBar: {
      children: [],
    },

    /**
     * Resize video player automatically on layout change
     */
    resizeManager: {},
  },

  /**
   * Tech options
   */
  tech: {
    /**
     * Use native CC implementation when possible
     */
    nativeTextTracks: true,
  },

  /**
   * Tech options
   */
  html5: {
    /**
     * Use native CC implementation when possible
     */
    nativeTextTracks: true,

    /**
     * Use own implementation for audio tracks
     */
    nativeAudioTracks: false,

    /**
     * Use own implementation for audio tracks
     */
    nativeVideoTracks: false,
    /**
     * Other options
     */
    vhs: {
      /**
       * Always use own implementation except Safari
       */
      overrideNative: !videojs.browser.IS_SAFARI,
    },
  },

};

/**
 * VideoJS IMA plugin options
 */
export const imaOptions = {
  /**
   * Do not play ad breaks automatically, this is handled by code
   * This is set to skip attempts to play ad at the beginning
   * if autoplay is disabled in browser, ad only blinks and disappear
   * instead, we're going to start ad after play is pressed for the
   * first time
   */
  autoPlayAdBreaks: false,

  /**
   * Rendering options
   */
  adsRenderingSettings: {
    /**
     * Assume all videos to be video/mp4 type, this allows player to
     * correctly handle ads on iOS
     */
    mimeTypes: ['video/mp4'],
  },
};

export const SHIFT_MULTIPLIER = 3;

export enum SHORTCUT {
  CC = 'CC',
  CONTROLS = 'CONTROLS',
  FULLSCREEN = 'FULLSCREEN',
  NEXT = 'NEXT',
  PAUSE_RESUME = 'PAUSE_RESUME',
  PREV = 'PREV',
  SKIP_BACK = 'SKIP_BACK',
  SKIP_FWD = 'SKIP_FWD',
  SLOW_DOWN = 'SLOW_DOWN',
  SPEED_UP = 'SPEED_UP',
  VOLUME_DOWN = 'VOLUME_DOWN',
  VOLUME_UP = 'VOLUME_UP',
  VOLUME_MUTE = 'VOLUME_MUTE',
  HELP = 'HELP',
  BEGIN = 'BEGIN',
  END = 'END',
}

export const SHORTCUT_KEYS: Record<SHORTCUT, ShortcutKey> = {
  [SHORTCUT.CC]: [['c'], 'C', 'c', 'Toggle closed captions'],
  [SHORTCUT.CONTROLS]: [['Tab'], 'Tab', '↹', 'Show controls'],
  [SHORTCUT.FULLSCREEN]: [['f'], 'F', 'f', 'Toggle fullscreen'],
  [SHORTCUT.NEXT]: [['>', '.'], 'Greater-than or Period', '> or .', 'Go next'],
  [SHORTCUT.PAUSE_RESUME]: [[' '], 'Spacebar', '␣', 'Pause / Resume'],
  [SHORTCUT.PREV]: [['<', ','], 'Less-than or Comma', '< or ,', 'Go previous'],
  [SHORTCUT.SKIP_BACK]: [['ArrowLeft'], 'Left Arrow', '⇐', `Rewind by ${SHIFT_TIME / 1000} seconds, ${SHIFT_MULTIPLIER} times faster when shift pressed`],
  [SHORTCUT.SKIP_FWD]: [['ArrowRight'], 'Right Arrow', '⇒', `Forward by ${SHIFT_TIME / 1000} seconds, ${SHIFT_MULTIPLIER} times faster when shift pressed`],
  [SHORTCUT.SLOW_DOWN]: [['ArrowDown'], 'Down Arrow', '⇓', 'Slow down twice'],
  [SHORTCUT.SPEED_UP]: [['ArrowUp'], 'Up Arrow', '⇑', 'Speed up twice'],
  [SHORTCUT.VOLUME_DOWN]: [['-'], 'Minus', '-', 'Volume down by 10%'],
  [SHORTCUT.VOLUME_UP]: [['+', '='], 'Plus or Equal', '+ or =', 'Volume up by 10%'],
  [SHORTCUT.VOLUME_MUTE]: [['m'], 'M', 'm', 'Mute sound'],
  [SHORTCUT.HELP]: [['?', '/'], 'Question mark or Slash', '? or /', 'Toggle Help'],
  [SHORTCUT.BEGIN]: [['Home'], 'Home', '⇱', 'Go to beginning'],
  [SHORTCUT.END]: [['End'], 'End', '⇲', 'Go to end'],
};
