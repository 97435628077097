import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { useProfile } from 'common/hooks/useProfile';

import { EntitiesListGroupContext } from './constants';
import {
  EntitiesListConfig, EntitiesListGroupContextType, EntitiesListGroupProps,
  EntitiesListType, EntitiesSize,
} from './types';
import { isEntitiesListSettings } from './utils';

const testSettings = (
  config?: Record<string, unknown>,
): EntitiesListConfig | void => {
  if (isEntitiesListSettings(config)) {
    return config;
  }
};

/**
 * Group wrapper, necessary when selection and/or
 * type/size control is required
 */
export const EntitiesListGroup = function EntitiesListGroup<
  T extends BaseEntityWithPk
>({
  children,
  onSelect,
  selectable,
  selected,
  setting,
  size: $size = EntitiesSize.LARGE,
  type: $type = EntitiesListType.TILE,
}: React.PropsWithChildren<EntitiesListGroupProps<T>>): JSX.Element {
  const { settings, saveSettings } = useProfile();
  const $config = settings.entities?.[setting];

  const [config, update] = React.useReducer(
    (
      s: EntitiesListConfig,
      up: Partial<EntitiesListConfig>,
    ): EntitiesListConfig => ({ ...s, ...up }),
    { type: $type, size: $size },
    (s) => ({ ...s, ...testSettings($config) }),
  );

  React.useEffect(() => {
    const s = testSettings($config);

    if (s) {
      update(s);
    }
  }, [$config, config.size, config.type]);

  const onConfig = React.useCallback(
    ($up: EntitiesListConfig): void => {
      update($up);

      if (setting) {
        saveSettings({ entities: { [setting]: $up } });
      }
    },
    [saveSettings, setting],
  );

  const value: EntitiesListGroupContextType<T> = React.useMemo(() => ({
    config,
    onConfig,
    onSelect,
    selectable,
    selected,
  }), [onSelect, onConfig, selected, config, selectable]);

  return (
    <EntitiesListGroupContext.Provider value={value}>
      {children}
    </EntitiesListGroupContext.Provider>
  );
};
