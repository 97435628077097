import cx from 'classnames';
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { NothingFound } from 'cms/components/NothingFound';
import { useSlider } from 'cms/components/Slider';
import { TilesLoading } from 'cms/components/TilesLoading';

import { EntitiesListContext } from '../constants';
import * as i from '../Items/Tiles/Items.m.less';
import { Wrapper } from '../Items/Tiles/Wrapper';
import * as s from './Tiles.m.less';
import { BaseEntitiesProps } from './types';

// eslint-disable-next-line react/function-component-definition
export function Base<T extends BaseEntityWithPk>({
  className,
  filter,
  loading,
  renderer,
  showNotFound,
  toTokens,
  singleLine,
  slider,
  ...props
}: BaseEntitiesProps<T>): JSX.Element {
  const { selectable, selected, config: { size } } = React.useContext(EntitiesListContext);
  const ref = React.useRef<HTMLDivElement>();

  const selecting = Boolean(selectable !== false && (selected?.length || props.checkbox));
  const {
    hasNext, hasPrevious,
    onNext, onPrevious,
  } = useSlider({
    container: ref.current,
    enabled: slider,
  });

  return (
    <div
      className={cx(
        s.tiles,
        props.disabled === true && s.disabled,
        slider && s.sliderContainer,
        className,
      )}
      data-size={size}
    >
      <Wrapper
        className={cx(
          (singleLine || slider) && s.single,
          slider && s.slider,
          slider && (
            (hasNext && hasPrevious && s.hasBoth)
            || (hasPrevious && s.hasPrevious)
            || (hasNext && s.hasNext)),
          selecting && i.selecting,
        )}
        filter={filter}
        renderer={renderer}
        ref={ref}
        toTokens={toTokens}
      >
        <TilesLoading
          className={i.item}
          count={typeof loading === 'number' ? loading : undefined}
          show={Boolean(loading)}
        />
      </Wrapper>
      {slider && (
        <>
          <button
            className={s.prev}
            disabled={!hasPrevious}
            onClick={onPrevious}
            type="button"
          >
            Previous
          </button>
          <button
            className={s.next}
            disabled={!hasNext}
            onClick={onNext}
            type="button"
          >
            Next
          </button>
        </>
      )}
      {showNotFound && !loading && props.items?.length === 0 && (
        <NothingFound text={showNotFound} />
      )}
    </div>
  );
}
