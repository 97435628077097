import { noop } from 'lodash/fp';
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import {
  CollectionItemExtraProps, EntitiesListContextType,
  EntitiesListGroupContextType, EntitiesListType, EntitiesSize,
  GameItemExtraProps, MomentItemExtraProps, PlayerItemExtraProps,
} from './types';

/**
 * Default list group context
 */
export const EntitiesListGroupContext = React.createContext<
  EntitiesListGroupContextType<BaseEntityWithPk>
>({
  config: {
    size: EntitiesSize.LARGE,
    type: EntitiesListType.TILE,
  },
});

/**
 * Default list context
 */
export const EntitiesListContext = React.createContext<
  EntitiesListContextType<BaseEntityWithPk>
>({
  config: {
    size: EntitiesSize.LARGE,
    type: EntitiesListType.TILE,
  },
  dragIndex: -1,
  dragPosition: -1,
  dropAllowed: false,
  findItem: () => ({ pk: '' }),
  items: [],
  setExpanded: noop,
  selectable: true,
});

interface ItemDefaults {
  collections: Omit<CollectionItemExtraProps<never>, 'position'>;
  games: GameItemExtraProps<never>;
  moments: Omit<MomentItemExtraProps<never>, 'position'>;
  players: PlayerItemExtraProps<never>;
}

interface Defaults {
  lists: ItemDefaults;
  tiles: ItemDefaults;
}

/**
 * Item property defaults, easy way to keep properties
 * the same for items and lists
 */
export const defaults: Defaults = {
  lists: {
    collections: { athlete: true, edited: true, created: true, game: true },
    games: { },
    moments: { created: true, edited: true, game: true, sport: true },
    players: { sport: true },
  },
  tiles: {
    collections: { athlete: true, edited: true, creator: true, created: true },
    games: { },
    moments: { creator: true, created: true, edited: true, game: true, sport: true },
    players: { sport: true },
  },
};
